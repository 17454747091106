// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-edicao-editarparceiros-js": () => import("./../src/pages/edicao/editarparceiros.js" /* webpackChunkName: "component---src-pages-edicao-editarparceiros-js" */),
  "component---src-pages-edicao-index-js": () => import("./../src/pages/edicao/index.js" /* webpackChunkName: "component---src-pages-edicao-index-js" */),
  "component---src-pages-empresa-js": () => import("./../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-execucaodeobras-js": () => import("./../src/pages/execucaodeobras.js" /* webpackChunkName: "component---src-pages-execucaodeobras-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-obras-js": () => import("./../src/pages/obras.js" /* webpackChunkName: "component---src-pages-obras-js" */),
  "component---src-pages-orcamento-js": () => import("./../src/pages/orcamento.js" /* webpackChunkName: "component---src-pages-orcamento-js" */),
  "component---src-pages-percolacao-js": () => import("./../src/pages/percolacao.js" /* webpackChunkName: "component---src-pages-percolacao-js" */),
  "component---src-pages-premoldados-js": () => import("./../src/pages/premoldados.js" /* webpackChunkName: "component---src-pages-premoldados-js" */),
  "component---src-pages-projetos-js": () => import("./../src/pages/projetos.js" /* webpackChunkName: "component---src-pages-projetos-js" */),
  "component---src-pages-sondagens-index-js": () => import("./../src/pages/sondagens/index.js" /* webpackChunkName: "component---src-pages-sondagens-index-js" */),
  "component---src-pages-sondagens-sptm-js": () => import("./../src/pages/sondagens/sptm.js" /* webpackChunkName: "component---src-pages-sondagens-sptm-js" */),
  "component---src-pages-sondagens-sptt-js": () => import("./../src/pages/sondagens/sptt.js" /* webpackChunkName: "component---src-pages-sondagens-sptt-js" */),
  "component---src-pages-sondagens-sr-js": () => import("./../src/pages/sondagens/sr.js" /* webpackChunkName: "component---src-pages-sondagens-sr-js" */),
  "component---src-pages-sondagens-st-js": () => import("./../src/pages/sondagens/st.js" /* webpackChunkName: "component---src-pages-sondagens-st-js" */),
  "component---src-pages-topografia-js": () => import("./../src/pages/topografia.js" /* webpackChunkName: "component---src-pages-topografia-js" */)
}

