import React, { useState, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import api from "../api";
import { navigate } from "gatsby";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AuthContext = createContext()


const GlobalContextProvider = ({children}) => {
    const [user, setUser] = useState()
    const [token, setToken] = useState()

    async function handleLogin(values){
        try {
            const response = await api.post("/api/auth/local", {
              identifier: values.username,
              password: values.password
            });
      
            setUser(response.data?.user);
            setToken(response.data.jwt);
      
            // if (checked) {
            //localStorage.setItem("userData", JSON.stringify(response.data));
            // }
      
            //navigate("/");
            notifySucess("Login realizado com sucesso")
        } catch (error) {
            notifyError("Usuário ou senha inválidos!")
        }
    }

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData) {
            console.log(userData)
            setToken(userData.jwt);
            setUser(userData?.user);
        }
    }, []);

    useEffect(() => {
        console.log(user)
    }, [user]);

    async function handleLogout(){
        localStorage.removeItem("userData");
        setUser();
        setToken();
        navigate("/login");
    }

    const notifyError = (message) => toast(
        <><h4 className="font-bold text-center base-golden-center mb-4">{message}</h4></>,
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
        }
      );
    const notifySucess = (message) => toast(
    <><h4 className="font-bold text-center base-golden-center mb-4">{message}</h4></>,
    {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
    }
    );

    return(
        <AuthContext.Provider
        value={{user, token, handleLogin, handleLogout}}
        >
            {children}
        </AuthContext.Provider>
    )
}

GlobalContextProvider.propTypes = { children: PropTypes.node };

export default GlobalContextProvider;